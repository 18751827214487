<template>
  <div class="screenHeight">
    <section class="maps">
      <div class="googleMap">
        <div>
          <a class="searchLink" @click="openSearch">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                 viewBox="0 0 24 24">
              <defs>
                <clipPath id="clip-Search">
                  <rect width="24" height="24"/>
                </clipPath>
              </defs>
              <g id="Search" clip-path="url(#clip-Search)">
                <g id="icon_action_search_24px" data-name="icon/action/search_24px">
                  <rect id="Boundary" width="26" height="26" fill="none"/>
                  <path id="_Color" data-name=" ↳Color"
                        d="M16.467,18h0l-5.146-5.134v-.813l-.278-.288a6.7,6.7,0,1,1,.721-.721l.288.278h.813L18,16.467,16.468,18ZM6.689,2.058a4.631,4.631,0,1,0,4.631,4.631A4.637,4.637,0,0,0,6.689,2.058Z"
                        transform="translate(3 3)" fill="#fff"/>
                </g>
              </g>
            </svg>
          </a>
          <SearchBar
            v-if="searchLocations"
            @closeSearch="closeSearch"/>
        </div>

        <main-map></main-map>
        <little-info-window v-if="selectedRoad !== null && roadIsSelected === true"></little-info-window>

        <mobile-navigation></mobile-navigation>
      </div>
    </section>
  </div>
</template>

<script>
  import MobileNavigation from '@/components/MobileNavigation';
  import SearchBar from '@/components/SearchBar.vue';
  import MainMap from '@/components/MainMap.vue';
  import LittleInfoWindow from '@/components/LittleInfoWindow.vue';

  export default {
    data() {
      return {
        roadIsSelected: false,
        selectedRoad: null,
        searchLocations: false,
      }
    },
    methods: {
      openSearch() {
        this.searchLocations = true;
        this.$store.dispatch('setSelectedRoad', null);
        this.$store.dispatch('setIsRoadSelected', false);
      },
      closeSearch() {
        this.searchLocations = false;
      }
    },
    watch: {
      "$store.getters.getIsRoadSelected": {
        handler: function () {
          this.roadIsSelected = this.$store.getters.getIsRoadSelected === true;
        },
        immediate: true
      },
      "$store.getters.getSelectedRoad": {
        handler: function () {
          if (this.$store.getters.getSelectedRoad) {
            this.selectedRoad = this.$store.getters.getSelectedRoad;
          } else {
            this.selectedRoad = null;
          }
        },
        immediate: true
      },
    },
    components: {
      MobileNavigation,
      SearchBar,
      MainMap,
      LittleInfoWindow
    }
  }
</script>

<style lang="scss" scoped>
  @import "../scss/variables.scss";

  .screenHeight {
    overflow: hidden;

    .searchLink {
      position: absolute;
      top: 32px;
      right: 15px;
      background-color: $primary;
      padding: 10px;
      z-index: 10;
      border: none;

      &:focus {
        border: none;
        outline: none;
      }

      .navIcon {
        color: white;
        height: 22px;
        width: 22px;
      }
    }
  }
</style>
