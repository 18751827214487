<template>
  <div>
    <div id="map"></div>
    <div class="currentLocation">
      <button class="custom-map-control-button" @click="initButton()">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 23 23">
          <defs>
            <clipPath id="clip-Locate_team">
              <rect width="26" height="26"/>
            </clipPath>
          </defs>
          <g id="Locate_team" data-name="Locate team" clip-path="url(#clip-Locate_team)">
            <g id="Group_898" data-name="Group 898" transform="translate(-653.958 9.752)">
              <path id="Path_1291" data-name="Path 1291"
                    d="M673.433.748a8.156,8.156,0,0,0-.1-.892,8,8,0,0,0-6.871-6.539V-8.752h-2v2.069A7.992,7.992,0,0,0,657.483.748h-2.525v2H657.6a8,8,0,0,0,6.855,6.431v3.069h2V9.179a8,8,0,0,0,6.854-6.431h2.646v-2Zm-7.975,6.5a6,6,0,1,1,6-6A6.006,6.006,0,0,1,665.458,7.248Z"/>
              <g id="Ellipse_4" data-name="Ellipse 4">
                <circle id="Ellipse_209" data-name="Ellipse 209" cx="3" cy="3" r="3"
                        transform="translate(662.458 -1.752)"/>
              </g>
            </g>
          </g>
        </svg>
        <span class="hidden-label"></span>
      </button>
    </div>
  </div>
</template>

<script>
import MarkerClusterer from '@googlemaps/markerclustererplus';
import mapsLoader from '../services/gMapsLoader';
import Road from '@/models/Road';

let map = null;
let userMarker = null;
let markers = [];
//let myPolyline = null;
export default {
  name: "Map",
  data() {
    return {
      infoWindow: "",
      roads: [],
      defaultCenter: {
        lat: 47.52,
        lng: 16.11
      },
      addClickEvent: false,
      markerClicked: false,
      pathLine: null,
      selectedRoad: null,
      roadId: null,
    }
  },
  async mounted() {
    const google = await mapsLoader();

    map = new google.maps.Map(document.getElementById('map'), {
      center: this.defaultCenter,
      zoom: 8,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapId: 'ffa5663c17880f19',
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
    });

    this.roads = await Road.all();

    if (this.$store.getters.getLocation) {
      this.defaultCenter = this.$store.getters.getLocation;
      map.setCenter(this.defaultCenter);
      this.setUserMarker(this.defaultCenter);
    }

    if (this.roads) {
      this.setLocationMarker();
    }

    this.checkSelection();
    map.addListener("click", () => {
      if (this.addClickEvent === true) {
        console.log('clicked')
        this.$store.dispatch('setSelectedRoad', null);
        this.$store.dispatch('setIsRoadSelected', false);
        this.addClickEvent = false;
      }
    }, {once: true});
  },
  methods: {
    initButton() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.$store.dispatch('setLocation', pos);
              this.setUserMarker(pos);
              map.setCenter(pos);
            },
            () => {
              this.handleLocationError(true, this.infoWindow, map.getCenter());
            }
        );
      } else {
        this.handleLocationError(false, this.infoWindow, map.getCenter());
      }
    },
    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
          browserHasGeolocation ?
              "Error: The Geolocation service failed." :
              "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    },
    setUserMarker(location) {
      // eslint-disable-next-line no-undef
      userMarker = new google.maps.Marker({
        position: location,
        title: 'my location',
        icon: '/img/userMarker.png',
        map,
      });

      userMarker.setPosition({
        lat: location.lat,
        lng: location.lng,
      });
    },

    setLocationMarker() {
      markers = this.roads.map((road) => {
        const pos = {
          lat: road.lat,
          lng: road.long,
        };
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: pos,
          icon: '/img/locationMarker.png',
          map,
        });

        marker.addListener("click", () => {
          this.$store.dispatch('setSelectedRoad', road.id);
          this.$store.dispatch('setIsRoadSelected', true);
          this.markerClicked = true;
          map.panTo(marker.getPosition());

          map.addListener("click", () => {
            if (this.addClickEvent === true) {
              console.log('clicked')
              this.$store.dispatch('setSelectedRoad', null);
              this.$store.dispatch('setIsRoadSelected', false);
              this.addClickEvent = false;
            }
          }, {once: true});
        });

        return marker;
      })

      new MarkerClusterer(map, markers, {
        maxZoom: 8,
        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      });

    },

    async checkSelection() {
      if (this.$store.getters.getIsRoadSelected === true) {
        this.addClickEvent = true;
        /*if(this.addClickEvent === true){
          map.addListener("click", () => {
            console.log("clicked")
            this.$store.dispatch('setSelectedRoad', null);
            this.$store.dispatch('setIsRoadSelected', false);
            this.addClickEvent = false;
          });
        }*/

        /*if(this.addClickEvent === false){
          map.clearListeners("click");
        }*/
        let center = await Road.find(this.$store.getters.getSelectedRoad);
        map.panTo({lat: center.lat, lng: center.long});
      }
    },
  },
  watch: {
    "$store.getters.getIsRoadSelected": {
      handler: function () {
        this.checkSelection();
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.currentLocation {
  .custom-map-control-button {
    position: absolute;
    bottom: 100px;
    right: 15px;
    padding: 15px;
    z-index: 10;
    border: none;
    border-radius: 50%;
    background-color: white;
    color: $secondary;

    &:focus {
      border: none;
      outline: none;
    }

    svg {
      fill: $secondary;
      font-size: 20px;
      display: block;
      margin: auto;
    }

    .hidden-label {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}

#map {
  width: 100%;
  height: 90vh;

  @media (min-width: 768px) {
    height: 100vh;
  }
}
</style>
