<template>
    <div class="allCheckpoints">
        <div class="container">
            <div class="row pt-3 pb-2">
                <div class="col-3" v-if="poi.images.length > 0">
                    <img :src="poi.images[0].url" alt="{{poi.name}}" class="smallerImage"/>
                </div>
                <div class="col-6" v-if="poi.images.length > 0">
                    <div class="row">
                        <div class="col-12">
                            <span>{{ poi.type.name }}</span>
                        </div>
                        <div class="col-12 pb-2">
                            <h5 class="pt-1"> {{ poi.name }} </h5>
                            <svg focusable="false" data-prefix="fas" data-icon="map-marker-alt"
                                 class="navIcon svg-inline--fa fa-map-marker-alt fa-w-12" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="currentColor"
                                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                            </svg>
                            <span v-if="distance!==null">{{ distance.toFixed(2) }} km</span>
                        </div>
                    </div>
                </div>
                <div class="col-9" v-else>
                    <div class="row">
                        <div class="col-12 pt-3">
                            <span>{{ poi.type.name }}</span>
                        </div>
                        <div class="col-12 pb-2">
                            <h5 class="pt-1"> {{ poi.name }} </h5>
                            <div v-if="poi.description" class="pb-2">
                                <span class="description" v-html="poi.description.substring(0, 30) + '...'"></span>
                            </div>
                            <svg focusable="false" data-prefix="fas" data-icon="map-marker-alt"
                                 class="navIcon svg-inline--fa fa-map-marker-alt fa-w-12" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="currentColor"
                                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                            </svg>
                            <span v-if="distance!==null">{{ distance.toFixed(2) }} km</span>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <a href="#" @click="addFavorite">
                        <svg v-bind:class="{show: isFavorite}" class="myList" xmlns="http://www.w3.org/2000/svg"
                             width="25" height="22" viewBox="0 0 25 22">
                            <defs>
                                <clipPath id="clip-Like_-_off">
                                    <rect width="25" height="22"/>
                                </clipPath>
                            </defs>
                            <g id="Like_-_off" data-name="Like - off" clip-path="url(#clip-Like_-_off)">
                                <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                    <path id="Path_1274" data-name="Path 1274"
                                          d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717ZM336.937-59.5l9.127,9.073,9.321-9.078v-3.83l-4.2-2.6-4.441,4.25-1.372.011L340.8-65.919l-3.863,2.566Z"
                                          fill="#1aa1ed"/>
                                </g>
                            </g>
                        </svg>

                        <svg :class="{show: !isFavorite}" class="myList" xmlns="http://www.w3.org/2000/svg"
                             width="25" height="22" viewBox="0 0 25 22">
                            <defs>
                                <clipPath id="clip-Like_-_on">
                                    <rect width="25" height="22"/>
                                </clipPath>
                            </defs>
                            <g id="Like_-_on" data-name="Like - on" clip-path="url(#clip-Like_-_on)">
                                <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                    <path id="Path_1274" data-name="Path 1274"
                                          d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717Z"
                                          fill="#1aa1ed"/>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <p class="text-center pt-1">My List</p>
                    <button @click="openLocation" class="btn btn-outline-secondary seeAll">See all</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        poi: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            filledIcon: false,
        }
    },
    computed: {
        isFavorite() {
            return this.$store.getters.isCheckpointInList(this.poi.id);
        },
        distance() {
            return window.google.maps.geometry.spherical.computeDistanceBetween(
                new window.google.maps.LatLng(this.userLocation.lat, this.userLocation.lng),
                new window.google.maps.LatLng(this.poi.lat, this.poi.long)) / 1000;
        },
        userLocation() {
            return this.$store.getters.getLocation;
        }
    },
    methods: {
        addFavorite() {
            if (this.isFavorite) {
                this.$store.dispatch('removeCheckpointList', this.poi.id);
                return;
            }

            this.$store.dispatch('setCheckpointList', this.poi.id);
        },
        openLocation() {
            this.$emit('openLocation', this.poi.id);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.allCheckpoints {
    box-shadow: 3px 3px 6px #e8e8e8;

    .smallerImage {
        width: 100%;
        height: 85%;
        margin: 10px 0;
        object-fit: cover;
    }

    .carouselCheckpoint {
        width: 100%;
        height: 160px;
        //padding: 0;

        @media screen and (min-width: 768px) {
            height: 240px;
        }
    }

    @media screen and (min-width: 768px) {
        max-height: 100%;
    }

    span {
        margin-right: 14px;
        color: #bebebe;
        font-size: 14px;
    }

    .navIcon {
        width: 12px;
        height: auto;
        fill: #bebebe;
        color: #bebebe;
        margin-right: 10px;
    }

    .myList {
        width: 30px;
        height: auto;
        color: #00A1ED;
        display: block;
        margin: auto;
        margin-top: 15px;
    }

    .myList.show {
        display: none;
    }

    .textList {
        display: block;
        margin: auto;
        text-align: center;
        color: #707070;
    }

    .navIcon {
        width: 12px;
        height: auto;
        fill: #bebebe;
        color: #bebebe;
        margin-right: 10px;
    }

    a {
        text-decoration: none;
        color: #707070;

        + p {
            display: block;
            margin: auto;
            text-align: center;
            color: #707070;
        }
    }

    h5 {
        color: #707070;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .inlineButtons {
        width: 100%;

        .infoButton {
            background-color: #EBEBEB;
            color: #707070;
            padding: 10px 12px;
            text-decoration: none;
            margin-right: 12px;

            svg {
                fill: #707070;
                width: 16px;
                height: auto;
                margin-right: 4px;
            }
        }


        @media screen and (max-width: 373px) {
            .infoButton {
                padding: 10px 10px;
                margin-right: 8px;

                svg {
                    margin-right: 2px;
                }
            }
        }

        @media screen and (max-width: 359px) {
            .infoButton {
                font-size: 14px;

                svg {
                    width: 14px;
                }
            }
        }
    }

    .highlight {
        color: #00D2ED;
        font-weight: 600;
        margin-right: 4px;
        font-size: 16px;
    }

    .seeAll {
        padding: 0px 0px;
        font-size: 16px;
        width: 100%;
        margin-top: 0px;

        &:hover, &:active {
            color: $secondary;
            background-color: white;
            border: 2px solid $secondary;
        }
    }
}

</style>
