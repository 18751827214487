<template>
    <div class="filters">
        <div class="">
            <div class="backTo">
                <a href="#" class="noLine" @click="closeFilters">
                    <span>FILTER</span>
                    <svg focusable="false" data-prefix="fas" data-icon="times"
                         class="xIcon svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 352 512">
                        <path fill="currentColor"
                              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                    </svg>
                </a>
            </div>
            <div class="container">
                <div class="row pt-3">
                    <div class="col-12">
                        <p class="filterTitle">Sort by</p>
                    </div>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ sort.sortByRelevance ? 'btn-secondary' : 'classicButton',  ]"
                            @click="sort.sortByRelevance = !sort.sortByRelevance">Relevance
                    </button>
                    <button :class="[ sort.sortByDistance ? 'btn-secondary' : 'classicButton',  ]"
                            @click="sort.sortByDistance = !sort.sortByDistance">Distance
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-3">
                    <div class="col-7">
                        <p class="filterTitle">Type of experience</p>
                    </div>
                    <div class="col-5 d-flex justify-content-end">
                        <a href="#" @click="addAllExperiences" class="addAll" v-if="!allExperiences">+ Add all</a>
                        <a href="#" @click="addAllExperiences" class="removeAll" v-if="allExperiences">- Remove all</a>
                    </div>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ type.adventure ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.adventure = !type.adventure">Adventure
                    </button>
                    <button :class="[ type.nature ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.nature = !type.nature">
                        Nature
                    </button>
                    <button :class="[ type.adrenaline ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.adrenaline = !type.adrenaline">Adrenalin
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ type.traditional ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.traditional = !type.traditional">Curtular / Traditional
                    </button>
                    <button :class="[ type.party ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.party = !type.party">
                        Party
                    </button>
                    <button :class="[ type.crafts ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.crafts = !type.crafts">
                        Crafts
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ type.relaxing ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.relaxing = !type.relaxing">Relaxing
                    </button>
                    <button :class="[ type.educational ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.educational = !type.educational">Educational
                    </button>
                    <button :class="[ type.cars ? 'btn-secondary' : 'classicButton',  ]"
                            @click="type.cars = !type.cars">Cars
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-3">
                    <div class="col-7">
                        <p class="filterTitle">Country</p>
                    </div>
                    <div class="col-5 d-flex justify-content-end">
                        <a href="#" @click="addAllCountries" class="addAll" v-if=!allCoutries>+ Add all</a>
                        <a href="#" @click="addAllCountries" class="removeAll" v-if=allCoutries>- Remove all</a>
                    </div>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ country.slovenia ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.slovenia = !country.slovenia">Slovenia
                    </button>
                    <button :class="[ country.croatia ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.croatia = !country.croatia">Croatia
                    </button>
                    <button :class="[ country.serbia ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.serbia = !country.serbia">Serbia
                    </button>
                    <button :class="[ country.kosovo ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.kosovo = !country.kosovo">Kosovo
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ country.bih ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.bih = !country.bih">
                        Bosnia &#38; Hercegovina
                    </button>
                    <button :class="[ country.montenegro ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.montenegro = !country.montenegro">Montenegro
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button :class="[ country.greece ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.greece = !country.greece">Greece
                    </button>
                    <button :class="[ country.bulgaria ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.bulgaria = !country.bulgaria">Bulgaria
                    </button>
                    <button :class="[ country.romania ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.romania = !country.romania">Romania
                    </button>
                </div>
                <div class="row d-flex justify-content-start pt-2">
                    <button class="mr-2" :class="[ country.albania ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.albania = !country.albania">Albania
                    </button>
                    <button class="mr-2" :class="[ country.macedonia ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.macedonia = !country.macedonia">North Macedonia
                    </button>
                    <button class="mr-2" :class="[ country.ukraine ? 'btn-secondary' : 'classicButton',  ]"
                            @click="country.ukraine = !country.ukraine">Ukraine
                    </button>
                </div>
                <div class="row pt-4">
                    <div class="col-12">
                        <p class="filterTitle">Price</p>
                        <div class="range-wrap">
                            <input type="range" class="form-range" min="0" max="450" step="50" v-model="price"
                                   id="priceRange">
                            <output class="bubble" id="bubblePrice"></output>
                        </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-12">
                        <p class="filterTitle">Distance</p>
                        <div class="range-wrap">
                            <input type="range" class="form-range" min="0" max="260" step="20" v-model="distance"
                                   id="distanceRange">
                            <output class="bubble" id="bubbleDistance"></output>
                        </div>
                    </div>
                </div>
                <div class="row pt-5 pb-3">
                    <div class="submitButton">
                        <button class="btn-secondary w-100" @click="setUpFilters">APPLY FILTERS</button>
                    </div>
                </div>
                <div class="row pt-2 pb-5 text-center">
                    <a href="#" class="resetFilters" @click="resetFilters">Reset filters</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sort: [],
            type: [],
            country: [],
            distance: 20,
            price: 150,
            allExperiences: false,
            allCoutries: false,
        }
    },
    mounted() {
        this.resetFilters();
        this.makeBubblePrice();
        this.makeBubbleDistance();
    },
    methods: {
        closeFilters() {
            this.$emit("closeFilters");
        },
        setUpFilters() {
            this.$emit("closeFilters", this.sort, this.type, this.country, this.distance, this.rangeValue);

        },
        resetFilters() {
            this.sort = {
                sortByRelevance: false,
                sortByDistance: false,
            };
            this.type = {
                adventure: false,
                nature: false,
                adrenaline: false,
                traditional: false,
                party: false,
                crafts: false,
                relaxing: false,
                educational: false,
                cars: false,
            };
            this.country = {
                slovenia: false,
                croatia: false,
                serbia: false,
                kosovo: false,
                bih: false,
                montenegro: false,
                greece: false,
                bulgaria: false,
                romania: false,
                albania: false,
                macedonia: false,
                ukraine: false,
            };
            this.distance = 20;

            this.rangeValue = 150;
        },
        addAllExperiences() {
            this.allExperiences = !this.allExperiences;
            if (this.allExperiences === true) {
                this.type = {
                    adventure: true,
                    nature: true,
                    adrenaline: true,
                    traditional: true,
                    party: true,
                    crafts: true,
                    relaxing: true,
                    educational: true,
                    cars: true,
                };
            } else {
                this.type = {
                    adventure: false,
                    nature: false,
                    adrenaline: false,
                    traditional: false,
                    party: false,
                    crafts: false,
                    relaxing: false,
                    educational: false,
                    cars: false,
                };
            }

        },
        addAllCountries() {
            this.allCoutries = !this.allCoutries;
            if (this.allCoutries === true) {
                this.country = {
                    slovenia: true,
                    croatia: true,
                    serbia: true,
                    kosovo: true,
                    bih: true,
                    montenegro: true,
                    greece: true,
                    bulgaria: true,
                    romania: true,
                    albania: true,
                    macedonia: true,
                    ukraine: true,
                };
            } else {
                this.country = {
                    slovenia: false,
                    croatia: false,
                    serbia: false,
                    kosovo: false,
                    bih: false,
                    montenegro: false,
                    greece: false,
                    bulgaria: false,
                    romania: false,
                    albania: false,
                    macedonia: false,
                    ukraine: false,
                };
            }
        },
        makeBubblePrice() {
            const val = this.price;
            const min = 0;
            const max = 450;
            const newVal = Number(((val - min) * 100) / (max - min));
            const bubble = document.querySelector("#bubblePrice");
            bubble.innerHTML = "$ " + this.price;

            // Sorta magic numbers based on size of the native UI thumb
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;

        },
        makeBubbleDistance() {
            const val = this.distance;
            const min = 0;
            const max = 250;
            const newVal = Number(((val - min) * 100) / (max - min));
            const bubble = document.querySelector("#bubbleDistance");
            bubble.innerHTML = this.distance + " km";

            // Sorta magic numbers based on size of the native UI thumb
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.27}px))`;
        }
    },
    watch: {
        price() {
            this.makeBubblePrice();

        },
        distance() {
            this.makeBubbleDistance();
        },

    },
    components: {},
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.filters {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    z-index: 100;
    overflow-y: scroll;

    @media screen and (min-width: 768px) {
        width: 400px;
        margin-left: 80px;
    }

    .backTo {
        padding-top: 30px;

        @media screen and (min-width: 768px) {
            padding-top: 0;
        }

        .noLine {
            text-decoration: none;
            text-align: left;
            padding-left: 10px;
            height: 60px;

            .xIcon {
                height: 20px;
                color: $grey-text-color;
                margin-left: 16px;
                right: 22px;
                position: absolute;
            }

            span {
                color: $grey-text-color;
                font-weight: 500;
            }

            @media screen and (min-width: 768px) {
                box-shadow: 3px 3px 6px #e8e8e8;
                padding: 19px 40px;
                width: 100%;
                text-align: left;
                display: flex;
                justify-content: space-between;

            }
        }
    }

    button {
        padding: 8px 16px;
        outline: none;
        border: none;
        width: auto;
        margin: 0 4px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .classicButton {
        background-color: rgba(190, 190, 190, 0.3);
        color: $body-color;
    }

    .resetFilters {
        color: $body-color;
        text-decoration: none;
    }

    .filterTitle {
        font-weight: 500;
    }

    .addAll, .removeAll {
        color: $secondary;
        text-decoration: none;
        font-weight: 500;
        text-transform: uppercase;
        text-align: right;
    }


    .range-wrap {
        position: relative;
        margin: 0 10px;

        .form-range {
            margin-top: 3px;
        }

        input[type='range'] {
            height: 5px;
            -webkit-appearance: none;
            position: relative;
            background-color: #bebebe;
            //overflow: hidden;

            &:focus {
                outline: none;
            }
        }


        input[type=range]::-webkit-slider-thumb {
            position: relative;
            z-index: 10;
            height: 24px;
            width: 22px;
            background-image: url("../assets/svg/Bar01.svg");
            background-repeat: no-repeat;
            background-color: transparent;
            cursor: pointer;
            -webkit-appearance: none;
            border: none;
            border-radius: 0;
            margin-top: -9px;
            //box-shadow: -100em 0 0 100em $secondary;
        }

        input[type=range]::-moz-range-thumb {
            z-index: 2;
            position: relative;
            height: 24px;
            width: 22px;
            background-image: url("../assets/svg/Bar01.svg");
            background-repeat: no-repeat;
            background-color: transparent;
            cursor: pointer;
            -webkit-appearance: none;
            border: none;
            border-radius: 0;
            margin-top: -9px;
        }

        input[type=range]::-ms-thumb {
            z-index: 2;
            position: relative;
            height: 24px;
            width: 22px;
            background-image: url("../assets/svg/Bar01.svg");
            background-repeat: no-repeat;
            background-color: transparent;
            cursor: pointer;
            -webkit-appearance: none;
            border: none;
            border-radius: 0;
            margin-top: -9px;
        }

        .bubble {
            background: transparent;
            color: #00A1ED;
            padding: 26px 12px;
            font-size: 18px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        ::-webkit-slider-runnable-track {
            height: 3px;
            color: $secondary;
        }

        ::-moz-range-track {
            height: 3px;
            color: $secondary;
        }

        ::-ms-fill-lower {
            height: 3px;
            color: $secondary;
        }
    }

    .submitButton {
        button {
            margin: auto;
            //padding: auto;
        }
    }

}
</style>
