<template>
    <div>
        <search-filters v-if="filters" @closeFilters="closeFilters"></search-filters>
        <div v-if="!filters">
            <div id="search-field">
                <div id="searchWrapper">
                    <a class="closeButton" @click="closeSearch">
                        <svg focusable="false" data-prefix="fas" data-icon="long-arrow-alt-left"
                             class="svg-inline--fa fa-long-arrow-alt-left fa-w-14" role="img"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                            </path>
                        </svg>
                    </a>
                    <input type="text" name="searchBar" id="searchBar" placeholder="Search" v-model="query"/>
                    <button class="cleanButton" @click="query = ''">
                        <svg focusable="false" data-prefix="fas" data-icon="times"
                             class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 352 512">
                            <path fill="currentColor"
                                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                            </path>
                        </svg>
                    </button>
                </div>
                <div class="container">
                    <div class="filterWrapper">
                        <a href="#" class="btn btn-secondary" @click="openFilters">
                            <svg focusable="false" data-prefix="fas" data-icon="sliders-h"
                                 class="svg-inline--fa fa-sliders-h fa-w-16" role="img"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512">
                                <path fill="currentColor"
                                      d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z">
                                </path>
                            </svg>
                            <span>Filter</span>
                        </a>
                    </div>
                </div>
                <div class="searchItemsBG">
                    <div class="recent" v-if="filteredRoads.length > 0">
                        <h5>Search results</h5>
                        <div v-for="poi in filteredRoads" :key="poi.id">
                            <PoiCard :poi="poi" @openLocation="openLocation"/>
                        </div>
                    </div>
                    <div class="recent" v-if="filteredRoads.length === 0">
                        <picture>
                            <source srcset="../assets/search-img.webp" type="image/webp" class="searchEmpty"
                                    alt="Search is empty -image">
                            <img src="../assets/search-img.jpg" class="searchEmpty" alt="Search is empty -image"/>
                        </picture>
                        <p class="biggerText text-center">No results</p>
                        <p class="text-center">Search has not found any results.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchFilters from './SearchFilters';
import Road from '@/models/Road';
import PoiCard from "@/components/poi/PoiCard";

export default {
    data() {
        return {
            filters: false,
            query: '',
            roads: [],
        }
    },
    mounted() {
        this.loadRoads();
    },
    computed: {
        filteredRoads() {
            if (this.query.length < 2) {
                return [];
            }

            const q = this.query.toLowerCase();

            return this.roads.filter((road) => {
                return road.name.toLowerCase().includes(q);
            });
        }
    },
    methods: {
        openFilters() {
            this.filters = true;
        },
        // eslint-disable-next-line no-unused-vars
        closeFilters(sort, type, country, distance, rangeValue) {
            //TODO - save kao varijable, i onda implementirati rezultate
            this.filters = false;
        },
        closeSearch() {
            this.$emit("closeSearch");
        },
        async loadRoads() {
            this.roads = await Road.all();
        },
        openLocation(id) {
            this.$store.dispatch('setSelectedRoad', id);
            this.$store.dispatch('setIsRoadSelected', true);

            this.closeSearch();
        }
    },
    components: {
        SearchFilters,
        PoiCard,
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

#search-field {
    height: 90vh;
    position: absolute;
    width: 100%;
    z-index: 100;
    margin-top: 30px;

    @media screen and (min-width: 768px) {
        background-color: white;
        left: 80px;
        width: 400px;
        margin-top: 0;
        height: 100vh;
    }

    #searchBar {
        width: 100%;
        height: 60px;
        padding-left: 70px;
        outline: none;
        border-radius: 4px;
        border: none;
        box-shadow: 3px 3px 6px #bebebe;
    }

    #searchWrapper {
        margin: 0px -11px;

        @media screen and (min-width: 768px) {
            margin: 0;
        }
    }

    .cleanButton,
    .closeButton {
        border: none;
        background-color: white;
        color: $grey-text-color;
        position: absolute;
        top: 14px;
        height: 30px;
        z-index: 2;

        svg {
            height: 20px;
        }
    }

    .cleanButton {
        right: 22px;
        transform: translateX(2px);
    }

    .closeButton {
        left: 22px;
    }

    .filterWrapper {
        padding-top: 6px;
        display: flex;

        .btn {
            padding: 6px 22px;
            text-transform: uppercase;
            font-size: 16px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
                height: 18px;
                margin-right: 14px;
            }
        }

        .openNow {
            margin-left: 10px;
            padding: 6px 22px;
            font-size: 16px;
            background-color: white;
            box-shadow: 0px 3px 3px $grey-text-color;
            border: none;
            color: $grey-text-color;
        }
    }

    .searchItemsBG {
        background-color: white;
        margin-top: 6px;
        min-height: 430px;

        .recent {
            background-color: white;
            color: $grey-text-color;
            margin-top: 10px;
            min-height: 100px;
            max-height: 500px;
            overflow-y: scroll;
            padding-bottom: 30px;

            @media screen and (min-width: 768px) {
                max-height: 100%;
            }

            .searchEmpty {
                width: 60%;
                display: block;
                margin: 40px auto 20px auto;
            }

            .biggerText {
                font-size: 20px;
            }

            h5 {
                padding: 14px 0px 0 14px
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                height: 100%;

                li {
                    border-bottom: 1px solid #bebebe;
                    padding: 10px 20px;

                    svg {
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>
